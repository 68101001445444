import React, { useState, useEffect } from 'react';
import Header from "./HeaderMenu";
import Footer from "./Footer";
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Layout = ({ children, footerRef }) => {
    const [className, setClassName] = useState('main-section');
    const { branding } = useSelector(state => state.language?.activelang);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/') {
            const timer = setTimeout(() => {
                setClassName('main-section main-bg');
            }, 0);
    
            return () => clearTimeout(timer);
        }
    }, [location.pathname]);
    useEffect(() => {
        const currentVersion = process.env.REACT_APP_VERSION;
        const cachedVersion = localStorage.getItem('appVersion');

        if (!cachedVersion || (cachedVersion !== currentVersion)) {
            // Clear outdated cache and update to the new version
            localStorage.setItem('appVersion', currentVersion);
            // Avoid infinite reloads
            if (cachedVersion) {
                window.location.reload(true);
            }
        }
    }, [children]);

    return (
        <>
            <div className={className}>
                <div className="ripple-animation" style={{ display: location.pathname === '/' ? 'block' : 'none' }}>
                    <div className="rim1"></div>
                    <div className="rim2"></div>
                    <div className="rim3"></div>
                    <div className="rim4"></div>
                </div>
                <Header />
                {children}
                <Footer branding={branding} footerRef={footerRef} />
            </div>
        </>
    );
};

export default Layout;